<template>
    <div v-if="item">
        <TabView v-model:activeIndex="activeIndex">
            <TabPanel header="Dados Gerais">
                <detalhe titulo="Status" :size="titleSize"><status :status="item.statusDescr"></status></detalhe>
                <detalhe titulo="Tipo do Produto" :size="titleSize">{{ item.tipoProdutoDescr }}</detalhe>
                <detalhe titulo="Produto" :size="titleSize">{{ item.produto }}</detalhe>
                <detalhe titulo="Descrição" :size="titleSize">{{ item.descricao }}</detalhe>
                <detalhe titulo="Quantidade" :size="titleSize">{{ $numeral(item.quantidade).format('0,0.00') }}</detalhe>
                <detalhe titulo="Preço por Unidade" :size="titleSize">{{ $numeral(item.precoPorUnidade).format('$0,0.00') }}</detalhe>
                <detalhe titulo="Desconto" :size="titleSize">{{ $numeral(item.descontoItem).format('$0,0.00') }}</detalhe>
                <detalhe titulo="Total" :size="titleSize">{{ $numeral(item.total).format('$0,0.00') }}</detalhe>
                <detalhe titulo="SalesOrderDetailId" :size="titleSize">{{ item.salesOrderDetailId }}</detalhe>
                <detalhe titulo="Integrado em" :size="titleSize" v-if="item.dataIntegracao">
                    {{ $dateFormat(item.dataIntegracao, 'DD/MM/YYYY HH:mm:ss') }}
                </detalhe>
            </TabPanel>
            <TabPanel header="Dados Recebidos">
                <vue-json-pretty
                    v-if="item?.itemDetalhe && item?.itemDetalhe.isJson()"
                    :data="JSON.parse(item?.itemDetalhe)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="item?.itemDetalhe && !item?.itemDetalhe.isJson()">{{ item?.itemDetalhe }}</span>
            </TabPanel>
            <TabPanel header="Integração">
                <vue-json-pretty
                    v-if="item?.integracaoObjeto && item?.integracaoObjeto.isJson()"
                    :data="JSON.parse(item?.integracaoObjeto)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="item?.integracaoObjeto && !item?.integracaoObjeto.isJson()">{{ item?.integracaoObjeto }}</span>
            </TabPanel>
            <TabPanel header="Retorno Integração">
                <vue-json-pretty
                    v-if="item?.integracaoRetorno && item?.integracaoRetorno.isJson()"
                    :data="JSON.parse(item?.integracaoRetorno)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="item?.integracaoRetorno && !item?.integracaoRetorno.isJson()">{{ item?.integracaoRetorno }}</span>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        item: {
            type: Object,
        },
    },

    data() {
        return {
            titleSize: '200',
        };
    },
};
</script>