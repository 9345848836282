<template>
    <div v-if="pagamento">
        <TabView v-model:activeIndex="activeIndex">
            <TabPanel header="Dados Gerais">
                <detalhe titulo="Status" :size="titleSize"><status :status="pagamento.statusDescr"></status></detalhe>
                <detalhe titulo="Tipo" :size="titleSize">{{ pagamento.tipoDescr }}</detalhe>
                <detalhe titulo="Status Comprometimento" :size="titleSize"><status :status="pagamento.statusComprometimentoDescr"></status></detalhe>
                <detalhe titulo="Comprometimento" :size="titleSize">{{ pagamento.comprometimento }}</detalhe>
                <detalhe titulo="Atualizado em" :size="titleSize" v-if="pagamento.dataAtualizacao">
                    {{ $dateFormat(pagamento.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                </detalhe>
            </TabPanel>
            <TabPanel header="Integração Pagamento">
                <vue-json-pretty
                    v-if="pagamento.objetoIntegracaoPagamento && pagamento.objetoIntegracaoPagamento?.isJson()"
                    :data="JSON.parse(pagamento.objetoIntegracaoPagamento)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="pagamento.objetoIntegracaoPagamento && !pagamento.objetoIntegracaoPagamento?.isJson()">{{
                    pagamento?.objetoIntegracaoPagamento
                }}</span>
            </TabPanel>
            <TabPanel header="Retorno Pagamento">
                <vue-json-pretty
                    v-if="pagamento.retornoIntegracaoPagamento && pagamento.retornoIntegracaoPagamento?.isJson()"
                    :data="JSON.parse(pagamento.retornoIntegracaoPagamento)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="pagamento.retornoIntegracaoPagamento && !pagamento.retornoIntegracaoPagamento?.isJson()">{{
                    pagamento.retornoIntegracaoPagamento
                }}</span>
            </TabPanel>
            <TabPanel header="Integração ERP">
                <vue-json-pretty
                    v-if="pagamento.objetoIntegracaoErp && pagamento.objetoIntegracaoErp?.isJson()"
                    :data="JSON.parse(pagamento.objetoIntegracaoErp)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="pagamento.objetoIntegracaoErp && !pagamento.objetoIntegracaoErp?.isJson()">{{ pagamento.objetoIntegracaoErp }}</span>
            </TabPanel>
            <TabPanel header="Retorno ERP">
                <vue-json-pretty
                    v-if="pagamento.retornoIntegracaoErp && pagamento.retornoIntegracaoErp?.isJson()"
                    :data="JSON.parse(pagamento.retornoIntegracaoErp)"
                    :showDoubleQuotes="true"
                ></vue-json-pretty>
                <span v-if="pagamento.retornoIntegracaoErp && !pagamento.retornoIntegracaoErp?.isJson()">{{ pagamento.retornoIntegracaoErp }}</span>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        pagamento: {
            type: Object,
        },
    },

    data() {
        return {
            titleSize: '200',
        };
    },
};
</script>