<template>
    <div v-if="carrinho">
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-4 icon-button" v-for="item in carrinho?.itens" :key="item.carrinhoItemId" @click="detalhar(item)">
                <div class="surface-card shadow-2 p-3 border-round">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium">{{ item.produto }}</span>
                            <span class="block text-700 font-medium mb-3">{{ item.descricao }}</span>
                            <div class="text-900 font-medium text-xl">{{ $numeral(item.total).format('$0,0.00') }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="block"
                        >Valor Unitário: <span class="text-green-500 font-medium">{{ $numeral(item.precoPorUnidade).format('$0,0.00') }}</span> | Desconto:
                        <span class="text-green-500 font-medium">{{ $numeral(item.descontoItem).format('$0,0.00') }}</span></span
                    >
                    <span class="block"
                        >Qtd: <span class="text-500">{{ item.quantidade }}</span></span
                    >
                </div>
            </div>
        </div>
    </div>
    <item-detalhar :item="item"></item-detalhar>
</template>

<script>
import ItemDetalhar from './ItemDetalhar';

export default {
    components: {
        ItemDetalhar,
    },

    props: {
        carrinho: {
            type: Object,
        },
    },

    data() {
        return {
            item: null,
        };
    },

    methods: {
        detalhar(item) {
            this.item = item;
        },
    },
};
</script>