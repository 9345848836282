<template>
    <detalhe titulo="Recebido em" :size="titleSize">{{ $dateFormat(carrinho.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}</detalhe>
    <detalhe titulo="Status" :size="titleSize"><status :status="carrinho.statusDescr"></status></detalhe>
    <detalhe titulo="Nome" :size="titleSize">{{ carrinho.nome }}</detalhe>
    <detalhe titulo="Descrição" :size="titleSize">{{ carrinho.descricao }}</detalhe>
    <detalhe titulo="Valor Total" :size="titleSize">{{ $numeral(carrinho.valorTotal).format('$0,0.00') }}</detalhe>
    <detalhe titulo="Responsável Financeiro" :size="titleSize">{{ carrinho.responsavelFinanceiro }}</detalhe>
    <detalhe titulo="SalesOrderId" :size="titleSize">{{ carrinho.salesOrderId }}</detalhe>
    <detalhe titulo="Última Integração CRM" :size="titleSize" v-if="carrinho.ultimaIntegracaoCrm">
        {{ $dateFormat(carrinho?.ultimaIntegracaoCrm, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Sucesso Última Integração CRM" :size="titleSize" v-if="carrinho.ultimaIntegracaoCrm">
        <status :status="carrinho.ultimaIntegracaoCrmSucesso ? 'SIM' : 'NÃO'"></status>
    </detalhe>
    <detalhe titulo="Retorno Última Integração CRM" :size="titleSize" v-if="carrinho.ultimaIntegracaoCrm"> {{ carrinho.ultimaIntegracaoCrmRetorno }}</detalhe>
    <detalhe titulo="Atualizado em" :size="titleSize">{{ $dateFormat(carrinho.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}</detalhe>
</template>

<script>
export default {
    props: {
        carrinho: {
            type: Object,
        },
    },

    data() {
        return {
            titleSize: '200',
        };
    },
};
</script>