<template>
    <painel titulo="Carrinho" icone="pi pi-shopping-cart" v-if="carrinho" :refreshFunction="obterCarrinhoAtualizado">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :carrinho="carrinho"></dados-gerais>
                </TabPanel>
                <TabPanel header="Dados Recebidos">
                    <vue-json-pretty v-if="carrinho?.dadosRecebidos" :data="JSON.parse(carrinho?.dadosRecebidos)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Itens">
                    <carrinho-itens :carrinho="carrinho"></carrinho-itens>
                </TabPanel>
                <TabPanel header="Pagamento">
                    <carrinho-pagamento :pagamento="carrinho?.pagamento"></carrinho-pagamento>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'CRM_Checkout_Carrinhos_Listagem' }"></btn-voltar>
    </painel>
</template>

<script>
import CarrinhoItens from './CarrinhoItens2';
import CarrinhoPagamento from './CarrinhoPagamento';
import Services from './services';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
        DadosGerais,
        CarrinhoItens,
        CarrinhoPagamento,
    },

    data() {
        return {
            carrinho: null,
        };
    },

    methods: {
        obterCarrinho() {
            this.$store.dispatch('addRequest');
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.carrinho = response.data;
                } else {
                    this.carrinho = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterCarrinhoAtualizado() {
            this.obterCarrinho();
        },
    },

    created() {
        this.obterCarrinho();
    },
};
</script>